import React, { Component } from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Dropdown from 'react-bootstrap/Dropdown';

import classes from './Layout.module.css';

class Layout extends Component {

    render () {
        return (
            <div className={classes.Layout}>
            <Navbar bg="light" expand="lg">
            <Navbar.Brand href="/" className={classes.Brand}><b>Toolvert</b></Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="mr-auto">
                    <Nav.Link href="/">Home</Nav.Link>
                    <Nav.Link href="/browse">Browse</Nav.Link>
                    <Nav.Link href="/timezone-converter">Time Zone Converter</Nav.Link>
                    <Nav.Link href="/timestamp-converter">Timestamp Converter</Nav.Link>
                    <Nav.Link href="/markdown-preview">Markdown Previewer</Nav.Link>
                    <Nav.Link href="/pretty-json">Pretty Json</Nav.Link>
                    <NavDropdown title="All Tools" id="basic-nav-dropdown">
                        <Dropdown.Header>Time</Dropdown.Header>
                        <NavDropdown.Divider />
                        <NavDropdown.Item href="/timezone-converter">Time Zone Converter</NavDropdown.Item>
                        <NavDropdown.Item href="/timestamp-converter">Timestamp Converter</NavDropdown.Item>
                        <NavDropdown.Divider />
                        <Dropdown.Header>Format</Dropdown.Header>
                        <NavDropdown.Divider />
                        <NavDropdown.Item href="/pretty-json">Pretty Json</NavDropdown.Item>
                        <NavDropdown.Item href="/markdown-preview">Markdown Previewer</NavDropdown.Item>
                </NavDropdown>
                </Nav>
            </Navbar.Collapse>
            </Navbar>

            <Container>
            <Row>
                {/* <Col lg={{span:8, offset:2}} md={{span:10, offset:1}} > */}
                <Col>
                <div className={classes.Content}>
                    <main>
                    {this.props.children}
                    </main>
                </div>
                </Col>
            </Row>
            </Container>

            </div>
        )
    }
}

export default Layout;