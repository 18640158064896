import React from 'react';

import {Link} from 'react-router-dom';
import { Helmet } from 'react-helmet';

class Browse extends React.Component{

    render() {

        return (
        <div className = "application">
            <Helmet>
            <title>Browse All Tools | Toolvert</title>
            <meta name="description" content="Browse all the tools for developers and coders." />
            </Helmet>

            <h2>All Tools | Toolvert</h2>
            <p>The goal of Toolvert is to provide a set of tools for developers.</p>
            <p>List of tools provided: </p>
            <ul>
                <label>Time</label>
                <li><Link to='/timezone-converter'>Time Zone Converter</Link></li>
                <li><Link to='/timestamp-converter'>Timestamp Converter</Link></li>
            </ul>
            <ul>
                <label>Format</label>
                <li><Link to='/markdown-preview'>Markdown Previewer</Link></li>
                <li><Link to='/pretty-json'>Pretty Json</Link></li>
            </ul>
        </div>
        )
    }
}

export default Browse;