import React from 'react';
import { Route, Switch, BrowserRouter as Router } from 'react-router-dom';

import Layout from './components/Layout/Layout';
import TimezoneConverter from './containers/TimezoneConverter/TimezoneConverter';
import TimestampConverter from './containers/TimestampConverter/TimestampConverter';
import PrettyJson from './containers/PrettyJson/PrettyJson';
import MarkdownPreview from './containers/MarkdownPreview/MarkdownPreview';
import Browse from './containers/Browse/Browse';
import Home from './containers/Home/Home';
import NotFound from './containers/NotFound/NotFound';

import './App.css';

class App extends React.Component {

    render () {
        let routes = (
            <Router>
            <Switch>
                <Route path="/browse" component={Browse} />
                <Route path="/timezone-converter" component={TimezoneConverter} />
                <Route path="/timestamp-converter" component={TimestampConverter} />
                <Route path="/markdown-preview" component={MarkdownPreview} />
                <Route path="/pretty-json" component={PrettyJson} />
                <Route path="/" exact component={Home} />
                { /* Catch all route */ }
                <Route path="*" component={NotFound} />
            </Switch>
            </Router>
        );

    return (
      <div>
        <Layout>
            {routes}
        </Layout>
      </div>
    );
  }
}

export default App;