import React from 'react';
import classes from './PrettyJson.module.css';

import { Helmet } from 'react-helmet';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

import JSONPretty from 'react-json-pretty';

class TimezoneConverter extends React.Component{

    constructor(props) {
        super(props);
        this.description = "This function converts a raw json to a pretty formatted json."
        this.state = {uglyJson: ''};
        this.uglyJsonInput = React.createRef();
        this.prettyJsonOutput = React.createRef();
    }

    handleConvert = () => {
        let uglyJsonInputValue = this.uglyJsonInput.current.value;
        this.setState({uglyJson: uglyJsonInputValue})
    }

    render() {

        return (
        <div className = {classes.Application}>

        <Helmet>
            <title>Pretty Json Converter | Toolvert</title>
            <meta name="description" content="Pretty Json formatter converts an ugly json to a pretty json." />
        </Helmet>

        <div className = {classes.Title}> 
            <h2><b>Pretty Json</b></h2> 
        </div>

        <div className = {classes.Description}> 
        <h5>Convert a ugly json to a pretty json</h5>
        <p>{this.description}</p>
        </div>
        
        <div className = {classes.Input}>
        <Form>

        <Form.Group controlId="formDatetime">
            <Row>

                <Col>
                    <Form.Control as="textarea" rows={10} ref={this.uglyJsonInput} placeholder="Post your ugly json here" />
                </Col>

            </Row>
        </Form.Group>
        </Form>

        <Row>
            <Col>
                <label>Your pretty json: </label>
                <div className={classes.PrettyJsonBox}>
                    <JSONPretty id="json-pretty" data={this.state.uglyJson}></JSONPretty>
                </div>
            </Col>
        </Row>

        </div>
        
        <div className={classes.Options}>
            <Container>
                <Row>
                    <Col>
                        <div className={classes.Option}>
                        <Button block variant="primary" onClick={this.handleConvert}> Convert </Button>
                        </div>
                    </Col>
                </Row>
            </Container>

        </div>

        </div>
        )
    }
}

export default TimezoneConverter;