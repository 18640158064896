import React from 'react';
import classes from './NotFound.module.css';

import { Helmet } from 'react-helmet';

class NotFound extends React.Component{

    render() {

        return (
        <div className = {classes.Application}>

        <Helmet>
            <title>404 Not Found | Toolvert</title>
            <meta name="description" content="Sorry the page you are looking for is not longer valid" />
        </Helmet>

        <div className = {classes.Title}> 
            <h2><b>404 Page Not Found</b></h2> 
        </div>

        <div className = {classes.Description}> 
        <p>Sorry, the page you are looking for is no longer here.</p>
        </div>
        
        </div>
        )
    }
}

export default NotFound;