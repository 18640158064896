import React from 'react';

import { Helmet } from 'react-helmet';

class TimezoneConverter extends React.Component{

    render() {

        return (
        <div className = "application">
            <Helmet>
            <title>Toolvert | Toolbox for developers, toolbox for coders.</title>
            <meta name="description" content="Pretty Json formatter converts an ugly json to a pretty json." />
            </Helmet>

            <h2>Toolvert | Toolbox for developers, toolbox for coders</h2>
            <br></br>
            <p>The goal of Toolvert is to provide a set of tools for developers and coders.</p>
            <p>
            There are many tools in the web, but most of them are not designed for developes and coders. 
            So Toolvert built multiple tools with developers and coders in mind.
            </p>
            <p>
            For example, our time zone converter tools are using the same datatime format as MySQL database, which is what most developers and coders are using day to day.
            </p>
        </div>
        )
    }
}

export default TimezoneConverter;