import React from 'react';
import classes from './MarkdownPreview.module.css';

import { Helmet } from 'react-helmet';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';

import ReactMarkdown from 'react-markdown/with-html';

class MarkdownPreview extends React.Component{

    constructor(props) {
        super(props);
        this.description = "This live markdown previewer shows the markdown live preview in a horizontal manner, with html tags supported."
        this.state = {markdown: '> Your markdown preview is here'};
        this.markdownInput = React.createRef();
    }

    handleConvert = () => {
        let markdownInputValue = this.markdownInput.current.value;
        this.setState({markdown: markdownInputValue});
    }

    render() {

        return (
        <div className = {classes.Application}>

        <Helmet>
            <title>Live Markdown Previewer | Toolvert</title>
            <meta name="description" content={this.description} />
        </Helmet>

        <div className = {classes.Title}> 
            <h2><b>Live Markdown Previewer (HTML supported)</b></h2> 
        </div>

        <div className = {classes.Description}> 
        <p>{this.description}</p>
        </div>
        
        <div className = {classes.Input}>
        <Form>

        <Form.Group>
            <Row>

                <Col>
                    <Form.Control 
                        as="textarea" 
                        rows={7} 
                        ref={this.markdownInput} 
                        placeholder="Post your markdown here" 
                        onChange={this.handleConvert}
                    />
                </Col>

            </Row>
        </Form.Group>
        </Form>

        <Row>
            <Col>
                <div className={classes.PreviewTitle}><label>  --- live markdown preview below --- </label></div>
                <div className={classes.PreviewBox}>
                    <ReactMarkdown
                        source={this.state.markdown}
                        escapeHtml={false}
                    />
                </div>
            </Col>
        </Row>

        </div>

        </div>
        )
    }
}

export default MarkdownPreview;